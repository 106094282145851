/* larger than mobile */
@media only screen and (min-width: 600px) {
	#photoGrid {
		margin-right: 8vh;
		margin-left: 8vh;
	}
}

/* anything up to tablet */
@media only screen and (min-width: 1px) and (max-width: 991px){
	#previewGrid {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		display: flex;
		justify-content: center;
	}
}

/* applies to all sizes, unless specified above */
h2 {
	font-family: var(--ivymode);
	color: var(--brown);
}

p {
	font-family: var(--brandon);
	color: var(--brown);
}

.ui.accordion .title:not(.ui) {
	color: var(--brown) !important;
	font: var(--brandon) !important;
	font-size: large !important;
	border: 1px solid var(--brown);
	border-radius: 500px;
	color: var(--brown);
    width: fit-content !important;
    padding-left: 2vh !important;
	padding-right: 2vh !important;
	padding-top: 1.5vh !important;
    padding-bottom: 1.5vh !important;
	margin: 1vh;
	margin-bottom: 3vh;
	margin-left: 0vh;
	float: left;
}

#accordianContainer {
	margin: 8vh;
	margin-bottom: 0vh;
	margin-top: 2vh;
	padding: 0vh !important;
}

#gridColumn {
	display: flex;
	justify-content: center;
}

#previewGrid {
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	justify-content: center;
}

#img {
	border: 1px solid rgb(11, 35, 53);
}
