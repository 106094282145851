#itgDetail {
	display: flex;
	flex-wrap: wrap;
}

#siteTitle {
	display: inline;
	margin-left: 1vh !important;
	font-size: 30px;
	color: var(--orange);
	background-color: none;
}

.all-nav-links {
	font-family: var(--ivymode);
}

#backButton {
	color: var(--orange);
	margin-left: 8vh;
	margin-right: 0vh !important;
}

#itgMenu {
	margin-top: 8vh;
	margin-bottom: 3vh;
	display: flex;
	align-items: flex-start;
}