:root {
  --ivymode: 'Merriweather', serif;;
  --brandon: 'Montserrat', sans-serif;
  --white: rgb(250, 250, 250);
  --brown: rgb(110, 87, 91);
  --orange: rgb(216, 123, 87);
  --yellow: rgb(254, 184, 117);
  --pink: rgb(233, 173, 173);
  --cream: rgb(255, 232, 201);
  --lt-orange: rgb(231, 135, 107);
  --lt-brown: rgb(159, 129, 134);
  --lt-pink: rgb(234, 204, 203);
  --pastel-orange: rgb(243, 178, 157);
}


a {
  text-decoration: none;
}
