.projectCta {
	background-color: var(--white);
	border: 1px solid var(--orange);
	border-radius: 500px;
	color: var(--orange);
    font-size: 16px;
    text-align: left;
    padding-left: 2vh;
	padding-right: 2vh;
	padding-top: 1.5vh;
    padding-bottom: 1.5vh;
	margin-left: 0vh;
	margin: 1vh;
	float: left;
}

.projectCta:hover {
	color: var(--white);
    background-color: var(--orange);
    font-size: 16px;
    text-align: left;
    padding-left: 2vh;
	padding-right: 2vh;
	padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-radius: 500px;
}

#projectsSection {
	margin: 8vh;
	margin-bottom: 2vh;
	padding: 0vh !important;
}


#projectImg {
	width: auto;
}


