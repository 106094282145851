:root {
  --white: rgb(250, 250, 250);
  --brown: rgb(110, 87, 91);
  --orange: rgb(216, 123, 87);
  --yellow: rgb(254, 184, 117);
  --pink: rgb(233, 173, 173);
  --cream: rgb(255, 232, 201);
  --lt-orange: rgb(231, 135, 107);
  --lt-brown: rgb(159, 129, 134);
  --lt-pink: rgb(234, 204, 203);
  --pastel-orange: rgb(243, 178, 157);
  --ivymode: 'Merriweather', serif;
  --brandon: 'Josefin Sans', sans-serif;
}

.App {
  text-align: center;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
