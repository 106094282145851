.aboutTitles {
	font-family: var(--ivymode);
	color: var(--brown)
}

#aboutSection {
	margin: 8vh;
	margin-bottom: 2vh;
	padding-left: 0vh !important;
	padding-right: 0vh !important;
}

.aboutCta {
	color: var(--brown);
	font-size: 16px;
	text-align: left;
	padding-left: 2vh;
	padding-right: 2vh;
	padding-top: 1.5vh;
	padding-bottom: 1.5vh;
	border: 1px solid var(--brown);
	border-radius: 500px;
	margin-top: 3vh;
	text-align: center;
}

.aboutCta:hover {
	color: var(--white);
	background-color: var(--brown);
	font-size: 16px;
	text-align: left;
	padding-left: 2vh;
	padding-right: 2vh;
	padding-top: 1.5vh;
	padding-bottom: 1.5vh;
	border-radius: 500px;
	margin-top: 3vh;
	text-align: center;
}

.certificationsGrid p {
	margin-bottom: 3vh;
}

/*mobile - make credential images show large abive each cred */
/* make text on buttons wrap */
@media only screen and (min-width: 1px) and (max-width: 471px){

	/*.aboutCta {

	}*/

	#certifications {
		padding-top: 7vh;
		padding-bottom: 0vh !important;
	}

	#gaImageParent {
		width: 100%;
	}
	#awsImageParent {
		width: 100%;
	}
	#googleImageParent {
		width: 100%;
	}
	.gaImage, .awsImage, .googleImage {
		flex-wrap: nowrap;
		margin-bottom: 2vh;
		/*width: 100%;*/
	}
}

/* larger than mobile, up to tablet */
@media only screen and (min-width: 480) and (max-width: 768) {

	#gaImageParent {
		width: 15%;
	}

	#awsImageParent {
		width: 15%;
	}

	#googleImageParent {
		width: 15%;
	}

	.gaImage, .awsImage, .googleImage {
		margin-bottom: 3vh;
		width: 10%;
		height: auto;
		flex-wrap: nowrap;
	}

	#aboutSection {
		margin: 8vh;
		margin-bottom: -3vh;
	}
}

/* larger than tablet, up to desktop */
@media only screen and (min-width: 769) and (max-width: 1500) {

	#gaImageParent {
		width: 15%;
	}

	#awsImageParent {
		width: 15%;
	}

	#googleImageParent {
		width: 15%;
	}

	.gaImage, .awsImage, .googleImage {
		margin-bottom: 3vh;
		width: 10%;
		height: auto;
		flex-wrap: nowrap;
	}

	#aboutSection {
		margin: 8vh;
		margin-bottom: -3vh;
	}
}


/* medium desktop (1501px) and larger */
@media only screen and (min-width: 1501px) {

	#gaImageParent {
		width: 10%;
	}

	#awsImageParent {
		width: 10%;
	}

	#googleImageParent {
		width: 10%;
	}

	.gaImage, .awsImage, .googleImage {
		margin-bottom: 1vh;
		width: 5%;
		height: auto;
		flex-wrap: nowrap;
	}

	#aboutSection {
		margin: 8vh;
		margin-bottom: -4vh;
	}
}
