.footer-nav-link {
	border: 1px solid var(--brown);
	border-radius: 500px;
	color: var(--brown);
    font-size: 16px;
    text-align: left;
    padding-left: 2vh;
	padding-right: 2vh;
	padding-top: 1.5vh;
    padding-bottom: 1.5vh;
	margin: 1vh;
	margin-left: 0vh;
	float: left;
}

.footer-nav-link:hover {
	color: var(--white);
    background-color: var(--brown);
    font-size: 16px;
    text-align: left;
    padding-left: 2vh;
	padding-right: 2vh;
	padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-radius: 500px;
}

#contact {
	margin: 8vh;
	margin-bottom: 2vh;
	padding: 0vh !important;
	padding-bottom: 6vh !important;
}
