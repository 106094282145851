@import '../../index.css';

/* for mobile */
@media only screen and (max-width: 600px){
	#menu-nav-links {
		margin-top: 8vh;
		margin-left: 0vh;
		margin-right: 0vh;
		margin-bottom: 0vh;
		padding: 0vh !important;
		font-family: 'Josefin Sans', sans-serif;
	}

	#navSection {
		margin-bottom: 0vh;
		margin-top: 2vh;
		padding: 0vh !important;
	}

	.nav-link {
		color: var(--orange);
		font-size: 16px;
		width: 15vh;
		text-align: left;
		padding-right: 4vh;
		padding-top: 1.5vh;
		padding-bottom: 1.5vh;
	}

	.nav-link:hover {
		color: var(--orange);
		text-decoration: underline;
	}

	#title {
		font-family: var(--ivymode);
		display: inline;
		margin: 8vh;
		margin-left: 8vh !important;
		margin-bottom: 3vh;
		margin-top: 6vh;
		float: left;
		font-size: 30px;
		color: var(--orange);
		background-color: none;
	}

	#menu {
		width: 100%;
		padding: 0px;
		padding-top: 2vh;
		background-color: var(--white);
	}

	#appBar {
		background-color: var(--white);
	}

}

/* for anything larger than mobile */
@media only screen and (min-width: 601px){
	#menu-nav-links {
		display: flex;
		justify-content: space-between;
		margin-top: 3vh;
	}

	.nav-link {
		color: var(--orange);
		font-size: 16px;
		width: 15vh;
		text-align: left;
		padding: 2vh;
	}

	.nav-link:hover {
		color: var(--white);
		background-color: var(--orange);
		font-size: 16px;
		width: 15vh;
		text-align: left;
		padding: 2vh;
		border-radius: 500px;
	}

	#title {
		font-family: var(--ivymode);
		padding-left: 0vh !important;
		padding-right: 0vh !important;
		font-size: 30px;
		color: var(--orange);
		background-color: none;
	}

	#navSection {
		margin: 8vh;
		margin-bottom: 0vh;
		margin-top: 8vh;
		padding: 0vh !important;
		width: 100%;
	}

	#all-nav-links {
		font-family: var(--ivymode);
	}

	#menu {
		width: 100%;
		padding: 0px;
		background-color: var(--white);
	}

	#appBar {
		background-color: var(--white);
	}
}